import { HTMLInputTypeAttribute } from "react"
import styled from "styled-components"

import { IconButton, TextField } from "@material-ui/core"

import { AddButton } from "src/ui/Button/AddButton"
import { colors } from "src/ui/colors"
import TrashSvg from "src/ui/icons/trash.svg"
import { spacing } from "src/ui/spacing"

export function ParadiseMultipleValuesField({
  textFields,
  onChange,
  required,
  inputType = "text",
  buttonLabel,
}: {
  textFields: string[]
  required?: boolean
  onChange: (textFields: string[]) => void
  buttonLabel: string
  inputType?: HTMLInputTypeAttribute
}) {
  const isRequired = textFields.length !== 0 && required

  const _textFields = isRequired
    ? [textFields[0] ?? "", ...textFields.slice(1)]
    : [...textFields]

  return (
    <Wrapper>
      {_textFields.map((field, index) => (
        <TextField
          value={field}
          required={required || isRequired}
          fullWidth
          key={`textfield-${index}`}
          onChange={(event) => {
            const updatedTextFields = [..._textFields]
            updatedTextFields[index] = event.target.value

            onChange(updatedTextFields)
          }}
          type={inputType}
          InputProps={{
            endAdornment:
              isRequired && _textFields.length === 1 ? undefined : (
                <IconButton
                  size="small"
                  onClick={() => {
                    const updatedTextFields = _textFields.filter(
                      (_, i) => index !== i
                    )

                    onChange(updatedTextFields)
                  }}
                >
                  <TrashSvg color={colors.brandText} width={18} />
                </IconButton>
              ),
          }}
        />
      ))}
      <div>
        <AddButton
          variant="minimal"
          onClick={() => {
            const updatedTextFields = [..._textFields, ""]

            onChange(updatedTextFields)
          }}
        >
          {buttonLabel}
        </AddButton>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
